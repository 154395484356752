import React from "react";
import isNode from "is-node";

import { formatImage } from "src/helpers/formatImage";

import { Props } from "./types";

const getSrcSet = (src: string, ratio: number) => {
  const array = [];
  array.push(`${formatImage(src, 600, ratio)} 600w`);
  array.push(`${formatImage(src, 1000, ratio)} 1000w`);
  array.push(`${formatImage(src, 1300, ratio)} 1300w`);
  array.push(`${formatImage(src, 1600, ratio)} 1600w`);
  array.push(`${formatImage(src, 2000, ratio)} 2000w`);
  array.push(`${formatImage(src, 2400, ratio)} 2400w`);
  return array.join(", ");
};

class Image extends React.Component<Props> {
  imageRef: any;
  observer: any;

  constructor(props: Props) {
    super(props);
    if (!isNode) {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: "0px 0px 500px 0px",
      });
    }
  }

  observeHandler = (entries: any) => {
    const { onImageLoaded } = this.props;

    for (const entry of entries) {
      if (entry.isIntersecting) {
        const src = this.imageRef.getAttribute("data-src") || "";
        const srcSet = this.imageRef.getAttribute("data-srcset") || "";
        this.imageRef.setAttribute("src", src);
        this.imageRef.setAttribute("srcset", srcSet);
        this.imageRef.onload = () => {
          this.imageRef.style.opacity = 1;
          if (onImageLoaded) {
            onImageLoaded();
          }
        };
        this.observer.unobserve(entry.target);
      }
    }
  };

  get placeholder() {
    const { ratio } = this.props;
    if (!ratio) {
      // Return an empty pixel
      return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89fkzEwAIswLkYLzKuwAAAABJRU5ErkJggg==";
    }
    const viewBox = `0 0 ${ratio} 1`;
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}"><rect fill="${encodeURIComponent(
      "#FFFFFF00"
    )}" width="100%" height="100%" /></svg>`;
  }

  render() {
    let { src, alt, size = "50vw", ratio, className, isCritical } = this.props;
    if (src && src.endsWith(".svg")) {
    } else {
      if (className) {
        className = className + " o-image";
      } else {
        className = "o-image";
      }
      if (isCritical) {
        className = className + " critical";
      }
    }

    const srcSet = getSrcSet(src, ratio);
    const formatSrc = formatImage(src, 2400, ratio);
    let webpChecked = false;
    let webpAccepted = false;

    if (!isNode && document) {
      webpChecked = document
        .getElementsByTagName("html")[0]
        .classList.contains("webp-checked");
      webpAccepted = document
        .getElementsByTagName("html")[0]
        .classList.contains("support-webp");
    }
    if (typeof alt !== "string") {
      alt = "";
    }

    if (src && src.endsWith(".svg")) {
      return <img src={src} alt={alt} className={className} />;
    }

    return (
      <React.Fragment>
        {isNode && (
          <React.Fragment>
            <noscript>
              <img
                className={className}
                sizes={`(max-width: 600px) 100vw,${size}`}
                srcSet={srcSet}
                src={formatSrc}
                alt={alt}
              />
            </noscript>
            <div />
            <img
              className={className}
              data-srcset={srcSet}
              sizes={`(max-width: 600px) 100vw,${size}`}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          </React.Fragment>
        )}
        {webpChecked &&
          (webpAccepted ? (
            <img
              ref={(x) => {
                if (x && !isCritical) {
                  this.observer.observe(x);
                  this.imageRef = x;
                }
              }}
              className={className}
              data-srcset={srcSet}
              sizes={`(max-width: 600px) 100vw,${size}`}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          ) : (
            <img
              ref={(x) => {
                if (x && !isCritical) {
                  this.observer.observe(x);
                  this.imageRef = x;
                }
              }}
              className={className}
              data-srcset={srcSet}
              sizes={`(max-width: 600px) 100vw,${size}`}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          ))}
      </React.Fragment>
    );
  }
}

export default Image;
