import React from "react";

import SbEditable from "storyblok-react";

import LinkComponent from "src/components/LinkComponent/LinkComponent";
import { getLinkAttributes } from "src/helpers/getLinkAttributes";
import { Props } from "./types";

const Link = ({ blok, className }: Props): JSX.Element => {
  const { url, title } = blok ? getLinkAttributes(blok) : {};

  return (
    <SbEditable content={blok}>
      {title ? (
        <LinkComponent className={className} to={url}>
          {title}
        </LinkComponent>
      ) : null}
    </SbEditable>
  );
};

export default Link;
