import React from "react";
import SbEditable from "storyblok-react";

import { useRecoilState } from "recoil";
import { Container, Row, Column } from "src/components/UI/Grid/Grid";
import { activeMenuState } from "src/recoil/atoms";


import { Props } from "./types";
import * as styles from "./Header.module.scss";

const Header = ({ blok }: Props): JSX.Element => {
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState);
  return (
    <SbEditable content={blok}>
      <Container as="header" className={styles.header} paddingTop={[15]}>
      </Container>
    </SbEditable>
  );
};

export default Header;
