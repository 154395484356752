import { atom } from "recoil";

export const activeMenuState = atom({
  key: "activeMenu",
  default: "" as string,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newActiveMenu) => {
        if (document) {
          const htmlEL = document.getElementsByTagName("html")[0];
          if (newActiveMenu) {
            htmlEL.classList.add("prevent-overflow");
          } else {
            htmlEL.classList.remove("prevent-overflow");
          }
        }
      });
    },
  ],
});
