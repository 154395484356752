import React from "react";
import { Box } from "reflexbox";
import SbEditable from "storyblok-react";

import * as styles from "./Text.module.scss";
import { Props } from "./types";

const Text = ({ blok }: Props): JSX.Element => {
  const { content } = blok;

  return (
    <SbEditable content={blok}>
      <Box className={styles.text}>{content && content}</Box>
    </SbEditable>
  );
};

export default Text;
