import React from 'react'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'
import { Flex, Box } from 'reflexbox'
import { Container } from 'src/components/UI/Grid/Grid'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import SignupFormMusic from 'src/components/SignupForm/SignupFormMusic'
import SignupFormFood from 'src/components/SignupForm/SignupFormFood'

import { Props } from './types'
import * as styles from './PageHome.module.scss'

const SITE_TYPE = process.env.GATSBY_SITE_TYPE

const PageHome = ({ blok }: Props): JSX.Element => {
  const {
    image,
    headline_first,
    headline_second,
    description,
    confirmation_text,
    confirmation_image,
    terms_text,
  } = blok || {}
  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper} paddingTop={[19, null, null, 20]}>
        <Flex
          alignItems="center"
          justifyContent="center"
          marginBottom={[19, null, null, 12]}
          className={styles.hero}
          width={1}
        >
          <Box className={styles.imageWrapper} width={1}>
            <Box className={styles.image} width={1}>
              {image && image.filename && (
                <Image size="300px" src={image.filename} />
              )}
            </Box>
            <Flex
              flexDirection="column"
              justifyContent="center"
              as="h1"
              className={classnames(
                styles.display,
                SITE_TYPE === 'food' ? styles.displayFood : styles.displayMusic
              )}
            >
              {headline_first && (
                <Box
                  paddingY={[8, null, null, 10]}
                  width={1}
                  className={styles.headlineLeft}
                >
                  <Markdown>{headline_first}</Markdown>
                </Box>
              )}
              {headline_second && (
                <Box
                  paddingY={[8, null, null, 10]}
                  width={'auto'}
                  alignSelf="flex-end"
                >
                  <Markdown className={styles.headlineRight}>
                    {headline_second}
                  </Markdown>
                </Box>
              )}
            </Flex>
          </Box>
        </Flex>
        {description && (
          <Box marginBottom={[22, null, null, 22]}>
            <Markdown className={styles.description}>{description}</Markdown>
          </Box>
        )}
      </Container>
      {/* {SITE_TYPE === 'music' && (
        <SignupFormMusic
          confirmationText={confirmation_text}
          confirmationImage={confirmation_image}
          termsText={terms_text}
        />
      )}
      {SITE_TYPE === 'food' && (
        <SignupFormFood
          confirmationText={confirmation_text}
          confirmationImage={confirmation_image}
          termsText={terms_text}
        />
      )} */}
    </SbEditable>
  )
}

export default PageHome
